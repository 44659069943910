<template>
  <div v-html="html"></div>
</template>

<script>
import { getWorkerPortalPage } from "@/api/app.js";
export default {
  data() {
    return {
      html: null,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      getWorkerPortalPage(13).then((result) => {
        if (this.$route.query.publicPortal) {
          if (result.data.publicPortal != 2) {
            this.$router.push({
              name: "login",
            });
          } else {
            this.html = result.data.secondDescription;
          }
        } else if (this.$route.query.publicApplication) {
          if (result.data.publicApplication != 2) {
            this.$router.push({
              name: "application",
            });
          } else {
            this.html = result.data.description;
          }
        }
      });
    },
  },
};
</script>

<style></style>
